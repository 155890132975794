import React, { PropsWithChildren } from "react";
import Styles from "./styles";

const { Wrapper } = Styles

interface IContentWrapper {}

const ContentWrapper = ({children}:PropsWithChildren<IContentWrapper>) => {
    return <Wrapper>{children}</Wrapper>
}

export default ContentWrapper