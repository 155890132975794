import React, {PropsWithChildren} from "react";
import Styles from "./styles";

const { StyledDoubleGrid } = Styles

interface IDoubleGrid {

}

const DoubleGrid = ({children}:PropsWithChildren<IDoubleGrid>) => {
    return <StyledDoubleGrid>
        {children}
    </StyledDoubleGrid>
}

export default DoubleGrid