import styled from "styled-components";

const Styles = {
  StyledH2: styled.h2`
    font-size: ${(props) => props.theme.fontSize.large};
    color: ${(props) => props.theme.color.secondBrand};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    font-family: ${(props) => props.theme.fontFamily.poppinsBold};

    @media only screen and (max-width: 1400px) {
      font-size: ${(props) => props.theme.fontSize.normal};
    }

    /*
    @media only screen and (max-width: ${(props) =>
        props.theme.breakPoints.mobile}) {
      font-size: ${(props) =>
        props.fontSize !== null && props.fontSize === "40px"
          ? "30px"
          : props.theme.fontSize.huge};
    }
    */
  `,
};
export default Styles;
