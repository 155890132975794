import styled from "styled-components";

const Styles = {
    StyledP: styled.p`
        font-size: ${(props) => props.theme.fontSize.base};
        color: ${(props) => props.theme.color.black};
        font-weight: normal;
        @media only screen and (max-width: 1400px) {
            font-size: ${(props) => props.theme.fontSize.tiny};
        }
    `
}
export default Styles