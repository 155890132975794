import React from "react";
import Styles from "./styles";

const { StyledH1, OrangeText } = Styles;

const Header = () => {
  return (
    <header>
      <StyledH1>
        Make your business ahead <br />{" "}
        <OrangeText>of the competition</OrangeText>
      </StyledH1>
    </header>
  );
};

export default Header;
