import styled from 'styled-components'

const Styles = {
    FeaturesWrapper: styled.section`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `,
    FeaturesGrid: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        gap: 0px 0px;
        grid-template-areas:
            'filled empty'
            'empty filled';
        margin-top: 200px;
        margin-left: 15px;
        @media screen and (max-width: 1400px) {
            grid-template-areas:
            'filled' ;
            grid-template-columns: 1fr;
            grid-template-rows: 240px 0px 0px 210px 240px 0px 0px 290px 350px 0px 0px 280px;
            margin-top: 145px;
            margin-bottom: 140px;
        }
    `,
    FeaturesContentWrapper: styled.div`
        width: 1430px;
        position: relative;
        display: flex;
        justify-content: center;
        z-index: 50;
        margin-left: 15px;
        margin-right: 15px;
        @media screen and (max-width: 1400px) {
            width: 100%;
        }
    `,
    FeaturesBackgroundWrapper: styled.div`
        position: absolute;
        width: 1400px;
        top: 30px;
        overflow: hidden;
        & > div:nth-child(1) {
            display: block;
        }
        & > div:nth-child(2) {
            display: none;
        }
        @media screen and (max-width: 1400px) {
            width: 100%;
            & > div:nth-child(1) {
                display: none;
            }
            & > div:nth-child(2) {
                display: block;
            }
            & img {
                width: 100%;
                height: 2383px;
                object-fit: cover;
            }
        }
    `,
    RoadFeaturesWrapper: styled.div`
        position: absolute;
        top: 170px;
        width: 79%;
        left: 5%;
    `,
    BackgroundMarginLeft: styled.div`
        background-color: #ecf0fd;
        position: absolute;
        left: 0;
        top: 113px;
        width: calc(((100vw - 1400px) / 2) - 7px);
        height: calc(100% - 86px);
    `,
    BackgroundMarginRight: styled.div`
        background-color: #ecf0fd;
        position: absolute;
        right: 0;
        top: 142px;
        width: calc(((100vw - 1400px) / 2) - 7px);
        height: calc(100% - 116px);
    `,
}
export default Styles
