import * as Yup from 'yup'

const phoneRegExp = /^((\\+[1-9 +]{1,4}[ \\-]*)|(\\([0-9 +]{2,3}\\)[ \\-]*)|([0-9 +]{2,4})[ \\-]*)*?[0-9 +]{3,4}?[ \\-]*[0-9 +]{3,4}?$/

const validationSchema = Yup.object({
    name: Yup.string().required('This field is required'),
    email: Yup.string()
        .email('Invalid email format')
        .required('This field is required'),
    company: Yup.string(),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
})

export default validationSchema 