import styled from "styled-components";

const Styles = {
    StyledDoubleGrid: styled.div`       
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 70px;
        grid-row-gap: 0px;
        @media screen and (max-width: ${(props) => props.theme.breakPoints.tablet}) {
            grid-template-columns: 1fr;
        }
    `,
}
export default Styles