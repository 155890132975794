import styled from 'styled-components'
import { Field } from 'formik'

const Styles = {
    ContactFormWrapper: styled.div`
        & > form {
            display: flex;
            flex-direction: column;
            position: relative;
            & > span {
                line-height: 0;
            }
        }
        margin: 15px;
        margin-bottom: 70px;
    `,
    InputWrapper: styled.div`
        min-width: 100%;
        display: flex;
        flex-direction: column;
    `,
    TextInput: styled(Field)`
        border-radius: 6px;
        border: none;
        outline: none;
        height: 54px;
        padding-left: 25px;
        padding-right: 25px;
        margin-bottom: 30px;
        &::-webkit-input-placeholder {
            font-size: 18px;
            font-family: 'Poppins-Regular', sans-serif;
            color: gray;
        }
        background-color: #D2DDEF;
        position: relative;
        z-index: 0;
        @media screen and (max-width: ${(props) => props.theme.breakPoints.mobile}){
            &::-webkit-input-placeholder {
                font-size: 14px;
            }
        }
    `,
    TextArea: styled.textarea`
        border: none;
        outline: none;
        border-radius: 6px;
        min-height: 54px;
        max-height: 200px;
        height: 100px;
        margin-bottom: 40px;
        resize: vertical;
        background-color: #D2DDEF;
        padding: 25px;
        &::-webkit-input-placeholder {
            font-size: 18px;
            font-family: 'Poppins-Regular', sans-serif;
            color: gray;
        }
        @media screen and (max-width: ${(props) => props.theme.breakPoints.mobile}){
            &::-webkit-input-placeholder {
                font-size: 14px;
            }
        }
    `,
    InputLabel: styled.label`
        margin-top: 35px;
        font-size: 14px;
    `,
    SendingMessage: styled.p`
        position: absolute;
        max-width: 70%;
        top: 50px;
        font-size: 14px;
    `
}

export default Styles
