import React from 'react'
import Styles from './styles'
import H2 from "../../../typography/H2"
import P from "../../../typography/P"
import { VectorImages } from "../../../images/index"

const { FlagIconImg } = VectorImages

interface IFeatureCard {
    title: string,
    text: string,
    className: string,
    flagRightDirection: boolean
}

const { FeatureCardWrapper, FlagIcon } = Styles

const FeatureCard = ({title, text, className, flagRightDirection}:IFeatureCard) => {
    return <FeatureCardWrapper className={className}>
        <FlagIcon flagRightDirection={flagRightDirection} src={FlagIconImg}/>
        <H2>{title}</H2>
        <P>{text}</P>
    </FeatureCardWrapper>
}

export default FeatureCard
