import styled from "styled-components";

const Styles = {
    LandingWrapper: styled.section`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        & p {
            max-width: 50%;
        }
        @media only screen and (max-width: ${(props) => props.theme.breakPoints.desktop}) {
            & p {
                max-width: 75%;
            }
        }
        @media only screen and (max-width: ${(props) => props.theme.breakPoints.mobile}) {
            & p {
                max-width: 90%;
            }
        }
    `,
    BackgroundImageWrapper: styled.div`
        position: absolute;
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        & img {
            object-fit: contain;
            max-height: calc(100vh - 100px);
        }
        @media only screen and (max-width: ${(props) => props.theme.breakPoints.tablet}) {
            & img {
                object-fit: contain;
                max-height: calc(100vh - 200px);
            }
        }
        @media only screen and (max-width: ${(props) => props.theme.breakPoints.mobile}) {
            display: none;
        }
    `,
    BackgroundMobileImageWrapper: styled.div`
        display: none;
        position: absolute;
        width: 100%;
        @media only screen and (max-width: ${(props) => props.theme.breakPoints.mobile}) {
            display: block;
        }
    `,
    SocialInfoWrapper: styled.div`
        position: absolute;
        bottom: 30px;
        right: 15px;
        z-index: 100;
        & > address{
            display: flex;
            flex-direction: row;
            font-style: normal;
            color: ${(props) => props.theme.color.secondBrand};
            & > div{
                margin-left: 50px;

            }
            @media only screen and (max-width: ${(props) => props.theme.breakPoints.tablet}) {
                flex-direction: column;
                & > div{
                    margin-left: 15px;
                    margin-bottom: 15px;
                }
            }
        }
        @media only screen and (max-width: ${(props) => props.theme.breakPoints.tablet}) {
            right: unset;   
            bottom: -50px;
        }
        & a {
            color: ${(props) => props.theme.color.secondBrand};
            transition: 0.3s;
            & :hover{
                color: ${(props) => props.theme.color.mainBrand};
            }
        }
    `,
    LandingContent: styled.div`
        height: 90vh;
        
    `
}
export default Styles