import React, { PropsWithChildren } from "react";
import Styles from "./styles";

interface IH2 {}

const { StyledH2 } = Styles;

const H2 = ({ children }: PropsWithChildren<IH2>) => {
  return <StyledH2>{children}</StyledH2>;
};

export default H2;
