import React from "react";
import Contact from "../domain/Contact";
import Features from "../domain/Features";
import Landing from "../domain/Landing";
import Head from "../components/Helmet";
import Footer from "../domain/Footer";

const IndexPage = () => (
    <>
        <Head />
        <Landing />
        <Features />
        <Contact />
        <Footer />
    </>
);

export default IndexPage;
