import React from 'react'
import { Helmet } from 'react-helmet'
import { RasterImages } from '../../images'

const { FaviconImg } = RasterImages

const Head = () => {
    return (
        <Helmet
            htmlAttributes={{
                lang: 'pl',
            }}
        >
            <meta charSet="utf-8" />
            <meta name="description" content="We are a leading provider of custom software. With us, you will stay ahead of your competition. Being a global company, we develop and sell products in the following business areas: enterprise applications, website development, digital marketing, CRM solutions, mobile application development, productivity and efficiency software, healthcare" />
            <meta name="keywords" content="get ahead of the competition, custom software, oakfusion, software house, bussines development, your software" />
            <title>Crowd out the competition</title>
            <link
                rel="canonical"
                href="https://oakfusion.gitlab.io/landingpage/crowd-out-the-competition-landing-page"
            />
             <link rel="icon" href={FaviconImg} />
            <meta lang="pl" />
        </Helmet>
    )
}

export default Head
