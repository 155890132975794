import styled, { css } from 'styled-components'

interface IFlagIcon{
    flagRightDirection: boolean
}

const Styles = {
    FeatureCardWrapper: styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        padding-left: 250px;
        padding-right: 150px;

        @media only screen and (max-width: 1400px) {
            margin-top: 0px;
            margin-bottom: 0px;
            padding-left: 0px;
            padding-right: 0px;

            max-width: 280px;
            width: 100%;

            & h2 {
                margin-left: 70px;
            }
        }
        & h2 {
            margin-bottom: 0px;
        }
    `,
    FlagIcon: styled.img<IFlagIcon>`
        position: absolute;
        left: 120px;
        ${(props) => props.flagRightDirection === true ? css`
            transform: scaleX(-1);
        ` : css`
            transform: scaleX(1);
        `}
        @media only screen and (max-width: 1400px) {
            width: 64px;
            height: 64px;
            transform: scaleX(1);
            left: 0px;
        }
    `,
}

export default Styles
