/** @format */

/// Recaptcha has been disabled!
/// To run it, you need to:
/// - uncomment the conditional if statement with the "verified" status;
/// - uncomment the Recaptcha component;
/// - download from google and set the appropriate recaptcha id for this page

import React, { useState } from 'react'
import DoubleGrid from '../../../components/DoubleGrid'
import emailjs from 'emailjs-com'
import validationSchema from './validation'
import Styles from './styles'
import Button from '../../../components/Button'
import { Form, ErrorMessage, Field, Formik } from 'formik'
import Recaptcha from 'react-recaptcha'
import { init } from 'emailjs-com'
init('user_hE3aWlAf88ZkxZg9y7mH6')

const {
    ContactFormWrapper,
    TextInput,
    InputLabel,
    TextArea,
    SendingMessage,
    InputWrapper,
} = Styles

const ContactForm = () => {
    const [verified, setVerified] = useState<boolean>(false)
    const [sending, setSending] = useState<boolean>(false)
    const [succes, setSuccess] = useState<boolean>(false)
    const [fail, setFail] = useState<boolean>(false)

    const recaptchaLoaded = () => {}
    const verifiedCallback = response => {
        if (response) {
            setVerified(true)
        }
    }

    const initialValues = {
        name: '',
        company: '',
        email: '',
        phone: '',
        message: '',
    }

    function sendEmail(object: any, resetForm: Function) {
        // if (verified === true) {
        if (sending === false) {
            setSending(true)
            emailjs
                .send(
                    'oakfusiongmail',
                    'template_18slhgg',
                    object,
                    'user_hE3aWlAf88ZkxZg9y7mH6'
                )
                .then(
                    result => {
                        setSuccess(true)
                        setSending(false)
                        resetForm()
                    },
                    error => {
                        setFail(true)
                        setSending(false)
                    }
                )
        }

        // } else {
        //     alert('Please verify that you are a human by checking a checkbox! ')
        // }
    }

    const TextError = props => {
        return (
            <span style={{ color: 'red', transition: '0.3s' }}>
                {props.children}
            </span>
        )
    }

    return (
        <ContactFormWrapper>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    sendEmail(values, resetForm)
                }}
            >
                <Form>
                    <DoubleGrid>
                        <InputWrapper>
                            <TextInput
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Name"
                            />
                            <ErrorMessage name="name" component={TextError} />
                        </InputWrapper>
                        <InputWrapper>
                            <TextInput
                                type="text"
                                name="company"
                                id="company"
                                placeholder="Company name (optional)"
                            />
                            <ErrorMessage
                                name="company"
                                component={TextError}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextInput
                                type="text"
                                name="email"
                                id="email"
                                placeholder="E-mail"
                            />
                            <ErrorMessage name="email" component={TextError} />
                        </InputWrapper>
                        <InputWrapper>
                            <TextInput
                                type="text"
                                name="phone"
                                id="phone"
                                placeholder="Phone number (optional)"
                            />
                            <ErrorMessage name="phone" component={TextError} />
                        </InputWrapper>
                    </DoubleGrid>

                    <Field name="idea" as="textarea">
                        {({ field, form, meta }) => {
                            return (
                                <TextArea
                                    value={field.value}
                                    onChange={field.onChange}
                                    as="textarea"
                                    id="message"
                                    name="message"
                                    placeholder="Your message"
                                />
                            )
                        }}
                    </Field>
                    <ErrorMessage name="idea" component={TextError} />
                    <TextInput
                        type="hidden"
                        name="site"
                        id="site"
                        value="Oakfusion.pl"
                    />
                    <div style={{ position: 'relative' }}>
                        {sending && (
                            <SendingMessage>
                                Wysyłanie wiadomości...
                            </SendingMessage>
                        )}
                        {!sending && succes && (
                            <SendingMessage>
                                Thank you! Your message has been sent. We will
                                contact You as soon as possible.
                            </SendingMessage>
                        )}
                        {fail && (
                            <SendingMessage>
                                Uuups :( something went wrong. Please check your
                                Internet connection or wait for a while and try
                                again.
                            </SendingMessage>
                        )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button type="submit">Contact us</Button>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        {/*
                        <Recaptcha
                            sitekey="6LehEngbAAAAAMVVKgjeeIgrn8tVmv2GUpqD0POv"
                            render="explicit"
                            onloadCallback={recaptchaLoaded}
                            verifyCallback={verifiedCallback}
                        />
                            */}
                    </div>
                </Form>
            </Formik>
        </ContactFormWrapper>
    )
}

export default ContactForm
