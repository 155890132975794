/** @format */

import React from "react";
import Styles from "./styles";
import H2 from "../../typography/H2";
import ContactForm from "./ContactForm";
import ContentWrapper from "../../components/ContentWrapper";

const { ContactWrapper } = Styles;

const Contact = () => {
  return (
    <ContactWrapper id="contact">
      <ContentWrapper>
        <H2>Write to us</H2>
        <ContactForm />
      </ContentWrapper>
    </ContactWrapper>
  );
};

export default Contact;
