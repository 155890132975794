import styled from "styled-components";

const Styles = {
    StyledH1: styled.h1`
        font-size: ${(props) => props.theme.fontSize.gigantic};
        color: ${(props) => props.theme.color.secondBrand};
        font-weight: ${(props) => props.theme.fontWeight.bold};
        font-family: ${(props) => props.theme.fontFamily.poppinsBold};
        @media only screen and (max-width: ${(props) => props.theme.breakPoints.tablet}) {
            font-size: ${(props) => props.theme.fontSize.big};
        }
        @media only screen and (max-width: ${(props) => props.theme.breakPoints.mobile}) {
            font-size: ${(props) => props.theme.fontSize.large};
        }
    `,
    OrangeText: styled.span`
        color: ${(props) => props.theme.color.mainBrand};
    `
}
export default Styles