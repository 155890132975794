import styled from "styled-components";

const Styles = {
    ContactWrapper: styled.section`
        display: flex;
        justify-content: center;
        margin-top: 200px;
        &  h2 {
          text-align: center;
          color: ${(props) => props.theme.color.mainBrand};
        font-size: ${(props) => props.theme.fontSize.gigantic};
        }
        & img {
            max-width: 100%;
            max-height: 500px;
            object-fit: contain;
            transition: 1s;
        }
        margin-bottom: 50px;
        @media only screen and (max-width:${(props) => props.theme.breakPoints.mobile}){
            & h2 {
                font-size: 24px;
            }
        }
        @media only screen and (max-width:${(props) => props.theme.breakPoints.tablet}){
            & h2 {
                text-align: left;
                font-size: 36px;
                margin-left: 15px;
            }
        }
    `,
    BeersImgWrapper: styled.div`
        & > div {
            justify-content: unset;
            align-items: unset;
        }
    `
    
}
export default Styles