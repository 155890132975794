import React, {PropsWithChildren} from "react";
import Styles from "./styles";

interface IP {
    
}

const { StyledP } = Styles

const P = ({children}:PropsWithChildren<IP>) => {
    return <StyledP>{children}</StyledP>
}

export default P