/** @format */

import React from "react";
import Styles from "./styles";
import { graphql } from "gatsby";
import ContentWrapper from "../../components/ContentWrapper";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery } from "gatsby";
import Header from "../../typography/Header";
import P from "../../typography/P";
import Button from "../../components/Button";
import { VectorImages } from "../../images";
import SocialIcon from "../../components/SocialIcon";
import { Link } from 'react-scroll'

const {
  LandingWrapper,
  BackgroundImageWrapper,
  SocialInfoWrapper,
  LandingContent,
  BackgroundMobileImageWrapper
} = Styles;
const { FacebookIconImg, InstagramIconImg, LinkedInIconImg } = VectorImages;

const Landing = () => {
  const data = useStaticQuery(
    graphql`
      query landingQuery {
        file(name: { eq: "landingBackground" }) {
          id
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
        }
      }
    `
  );

  const {
    file: {
      childImageSharp: { gatsbyImageData },
    },
  } = data;

  return (
    <LandingWrapper>
      <BackgroundImageWrapper>
        <GatsbyImage
          image={gatsbyImageData}
          alt="Crowd out the Competition landing background"
        />
      </BackgroundImageWrapper>
      <ContentWrapper>
        <LandingContent>
          <Header />
          <P>
            Business these days is a constant race against the competition. To
            participate in this race you will need the best software to meet
            your business customer's needs and achieve your goals faster than
            your competitors.
          </P>
          <BackgroundMobileImageWrapper>
            <GatsbyImage
              image={gatsbyImageData}
              alt="Crowd out the Competition landing background"
            />
      </BackgroundMobileImageWrapper>
                <Link
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={1000}
                >
                  <Button>Contact us</Button>
                </Link>
        </LandingContent>
        <SocialInfoWrapper>
          <address>
            <div>
              <span>Żmigrodzka 244</span>
              <br />
              <span>51-131 Wrocław, Poland</span>
            </div>
            <div>
              <span>+48 797 743 064</span>
              <br />
              <span>
                <a href="mailto:office@oakfusion.pl">office@oakfusion.pl</a>
              </span>
            </div>
            <div>
              <SocialIcon icon={FacebookIconImg} to="https://www.facebook.com/OakfusionSoftwareHouse/" />
              <SocialIcon icon={InstagramIconImg} to="https://www.instagram.com/oakfusion/" />
              <SocialIcon icon={LinkedInIconImg} to="https://pl.linkedin.com/company/oakfusion" />
            </div>
          </address>
        </SocialInfoWrapper>
      </ContentWrapper>
    </LandingWrapper>
  );
};

export default Landing;
