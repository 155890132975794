import styled from "styled-components";

const Styles = {
    Wrapper: styled.div`
        width: ${(props) => props.theme.breakPoints.desktop};
        position: relative;
        z-index: 50;
        margin-left: 15px;
        margin-right: 15px;
        @media screen and (max-width: ${(props) => props.theme.breakPoints.desktop}){
            width: calc(100% - 15px);
        }
    `
}

export default Styles