import { v4 as uuidv4 } from 'uuid'

const FeatureCardsData = [
    {
        id: uuidv4(),
        title: 'Functionality',
        text:
            'At the start of the skirmish with the competition, it will be essential that your business requirements are met. Thanks to software you will achieve specific goals, business effects.',
    },
    {
        id: uuidv4(),
        title: 'Reliability ',
        text:
            ' The race will not be easy but rest the reliability of your software will be assured',
    },
    {
        id: uuidv4(),
        title: 'Efficiency',
        text:
            "Running will require endurance from you, especially your software will improve customer relations. efficient software will also increase your company's productivity",
    },
    {
        id: uuidv4(),
        title: 'Usability',
        text:
            'During the race you will need to be well versed. You will achieve this excellence by implementing User Experience workshops and your software will be understandable, easy to learn and highly attractive to use which will increase your chances of market success',
    },
    {
        id: uuidv4(),
        title: 'Sustainability',
        text:
            'It is known that the situation in the race is constantly changing so the software should evolve. Your new business will run smoothly because of the ease of software maintenance. By improving usability and increasing efficiency, you will quickly gain an edge over sluggish competitors who are still fighting software bugs of the past and present.',
    },
    {
        id: uuidv4(),
        title: 'Portability',
        text:
            'To win the race against your rivals you need to be mobile. Your software will be platform independent which means you will be able to transfer it to your phone or computer without any problems.',
    },
    {
        id: uuidv4(),
        title: 'Balance',
        text:
            'Every discipline requires the player to balance the resources that success consists of. Your software development time and budget must be balanced with its reliability. You are almost at the finish line! The most important thing is the success of your project!',
    },
]

export default FeatureCardsData
