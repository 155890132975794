/** @format */

import React from "react";
import Styles from "./styles";
import FeatureCardsData from "./data";
import FeatureCard from "./FeatureCard";
import { myTheme } from "../../MyTheme";
import ContentWrapper from "../../components/ContentWrapper";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

const {
  fontSize: { huge },
} = myTheme;

const {
  FeaturesWrapper,
  FeaturesGrid,
  FeaturesBackgroundWrapper,
  FeaturesContentWrapper,
  BackgroundMarginRight,
  BackgroundMarginLeft,
  RoadFeaturesWrapper
} = Styles;

const Features = () => {
  const data = useStaticQuery(
    graphql`
      query featuresQuery {
        allFile(
          filter: {
            name: { in: ["featuresBackground", "featuresRoad", "featuresRoadMobile"] }
          }
        ) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    `
  );

  const { edges } = data;

  return (
    <FeaturesWrapper>
      <BackgroundMarginRight />
      <BackgroundMarginLeft />
      <FeaturesContentWrapper>
        <FeaturesBackgroundWrapper>
          <GatsbyImage
            image={data.allFile.edges[2].node.childImageSharp.gatsbyImageData}
            alt="Crowd out the Competition features background"
          />
          <GatsbyImage
            image={data.allFile.edges[1].node.childImageSharp.gatsbyImageData}
            alt="Crowd out the Competition features background"
          />
        </FeaturesBackgroundWrapper>
        <FeaturesGrid>
          {FeatureCardsData.map((card, idx) => (
            <>
              {idx % 2 !== 1 ? (
                <>
                  <FeatureCard
                    flagRightDirection={false}
                    className="filled"
                    key={idx}
                    {...card}
                  />
                  <div className="empty"></div>
                </>
              ) : (
                <>
                  <div className="empty"></div>
                  <FeatureCard
                    flagRightDirection={true}
                    className="filled"
                    key={idx}
                    {...card}
                  />
                </>
              )}
            </>
          ))}
        </FeaturesGrid>
      </FeaturesContentWrapper>
    </FeaturesWrapper>
  );
};

export default Features;
