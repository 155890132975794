import React, {PropsWithChildren} from "react";
import Styles from "./styles";
import { VectorImages } from "../../images/index"

const { ArrowButtonImg } = VectorImages

interface IButton {
    type?: "button" | "submit" | "reset" 
}

const {StyledButton} = Styles

const Button = ({children, type}:PropsWithChildren<IButton>) => {
    return <StyledButton type={type ? type : "button"}>
        <span>{children}</span>
        <img src={ArrowButtonImg} alt="Button arrow symbol"/>
    </StyledButton>
}

export default Button