import styled from "styled-components";

const Styles = {
    StyledButton: styled.button`
        position: relative;
        background: rgb(240,115,19);
        background: linear-gradient(180deg, rgba(240,115,19,1) 0%, rgba(255,12,0,1) 100%);
        box-shadow: 4px 4px 10px ${(props) => props.theme.color.mainBrand};
        border-radius: 6px;
        padding: 15px 60px 15px 20px;
        font-size: ${(props) => props.theme.fontSize.tiny};
        font-weight: ${(props) => props.theme.fontWeight.normal};
        color: ${(props) => props.theme.color.white};
        outline: none;
        border: none;
        cursor: pointer;
        display: inline-block;
        width: auto;
        position: relative;
        z-index: 50;
        font-family: 'Cantarell', sans-serif;
        & > span {
            font-family: ${(props) => props.theme.fontFamily.poppinsRegular};
        }
        & > img {
            position: absolute;
            right: 18px;
            padding-top: 2px;
        }
    `
}
export default Styles